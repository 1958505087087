import * as React from 'react';
import Button from '@mui/material/Button';
import {Snackbar, Alert} from '@mui/material';
import Slide from '@mui/material/Slide';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Notification({message, severity, open_flag} ) {
    const [open, setOpen] = React.useState(false);
    const [transition, setTransition] = React.useState(()=>TransitionRight);
      
    React.useEffect(()=>{
        if(open_flag)
            setOpen(true);
    },[open_flag]);


    const handleClick = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
        {/* <Button onClick={handleClick()}>Right</Button> */}

        <Snackbar
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
            TransitionComponent={transition}
            message="I love snacks"
            key={transition ? transition.name : ''}
        >
                <Alert onClose={handleClose} severity= {severity? severity:"success"} sx={{ width: '100%' }}>
                    {message}
                </Alert>
        </Snackbar>
        </div>
    );
}