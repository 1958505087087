import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Chip from '@mui/material/Chip';

import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Dialog, Stack } from '@mui/material';
import Fab from '@mui/material/Fab';

import { collection, getDocs, doc, setDoc ,query, where} from "firebase/firestore";
import { getDatabase, ref, onValue} from "firebase/database";
import {db, database} from '../Firebase/FirebaseConfig';

import FormUser from '../components/Forms/FormUser';
import DataUser from '../components/LayoutUser/DataUser';
import DeleteUserModal from '../components/Modales/DeleteUserModal';

function createUser(nombre, cedula, telefono, email, puntaje, contrasena, respuestas, videoUrl,completed) {
  return {
    nombre,
    cedula,
    telefono,
    email,
    puntaje,
    contrasena, 
    respuestas, 
    videoUrl,
    completed,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'usuario',
    numeric: false,
    disablePadding: true,
    label: 'Usuario',
  },
  {
    id: 'estado',
    numeric: false,
    disablePadding: false,
    label: 'Estado',
  },
  {
    id: 'acciones',
    numeric: false,
    disablePadding: false,
    label: 'Acciones',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align='left'
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            padding= 'normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} seleccionados
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Usuarios Registrados
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filtrar lista">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function LayoutUsers() {
  const [view, set_view] = useState('users'); //users - user_form

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nombre');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users_list, set_user_list] = useState([]);

  //  Elementos del modal de borrado 
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const [user_to_delete, set_user_to_delete] = useState({});
  const [user_to_view, set_user_to_view] = useState({});

  const openInfoUser = (user) => {
    // alert(user.momento1.evaluacion.IE.calificacion);
    // console.log('usuario seleccionado', user)
    set_user_to_view(user);
    set_view('user_data');
  }

  const openDeleteModal = (user) =>{
    set_delete_modal_open(true);
    set_user_to_delete(user);
  }

  useEffect(()=>{
    getDataFirebase();
  }, []);

  const getDataFirebase = async() =>{
    

    set_user_list([]);

    // const db = database;
    const starCountRef = ref(database, 'respuestas');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();

      let usersList = [];
      for (let id in data) {
        const user_data = data[id];

        let completed_label = 'Sin comenzar';
        let completed_color = 'error'
        let completed = false;

        if(user_data.progreso > 0 && user_data.progreso < 6){
          completed_label = 'Sin terminar';
          completed_color = 'warning';
        }
        else if(user_data.progreso >= 6){
          completed_label = 'Completado';
          completed_color = 'success';
          completed = true;
        }
        usersList.push({ id,completed, completed_label, completed_color, ...data[id] } );
      }

      set_user_list(usersList);
      // console.log('usersList', usersList);
    });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users_list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users_list.length) : 0;

  
  if(view === 'users'){
    return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
          {users_list.length>0? 
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users_list.length}
              />
              
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
                {(rowsPerPage > 0
                    ? stableSort(users_list, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : stableSort(users_list, getComparator(order, orderBy))
                  )
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    
                    // console.log('usuarioooo', row)
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.usuario}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          // padding="none"
                        >
                          {row.usuario}
                        </TableCell>
                        
                        <TableCell align="right" > 
                          <Chip size="small" sx={{width:'100%'}}
                            label={row.completed_label} 
                            color={row.completed_color} 
                            icon={row.completed?<DoneIcon /> : <CloseIcon/>}/>
                        </TableCell>
                        <TableCell align="right">

                          <Tooltip title="Ver respuestas del usuario">
                            <IconButton aria-label="view" color='info' onClick={()=>openInfoUser(row)}>
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                          
                          <Tooltip title="Eliminar usuario">
                            <IconButton aria-label="delete" color='error' onClick={()=>openDeleteModal(row)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            :
              
              <Alert sx={{m:3, width:'80%'}} severity="info">Lo sentimos, <strong> no hay usuarios registrados por el momento!</strong> </Alert>
            }
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            component="div"
            count={users_list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            showFirstButton
            showLastButton
            labelRowsPerPage='Filas por página'
            labelDisplayedRows = {({ from, to, count }) => { return `${from} – ${to} de ${count !== -1 ? count : `más de ${to}`}`; }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Información condensada"
        />
  
          <Fab sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
            }} aria-label='añadir' color='primary'
            onClick={()=>set_view('form_user')}>
            <AddIcon />
          </Fab>

          <DeleteUserModal 
            open={delete_modal_open} 
            set_open={set_delete_modal_open} 
            userData={user_to_delete}
            onDelete={getDataFirebase}>
          </DeleteUserModal>
      </Box>
    );
  } else if(view === 'form_user'){
    return (<FormUser set_view={set_view} onCreate={getDataFirebase}></FormUser>);
  } else if(view === 'user_data'){
    return (<DataUser data={user_to_view} set_view={set_view}></DataUser>);
  }
  
}