import './App.css';
import {
	HashRouter as Router,
	Switch,
	Route,
  Redirect,
} from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {theme} from './theme/index';

import { AuthProvider } from './components/Authentication/Auth';
import PrivateRoute from './components/Authentication/PrivateRoute';
import Login from './views/Login';
import NotFound from './views/404';
import MainLayout from './views/MainLayout';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <div className="App">
            <Switch>
              <Redirect exact from="/" to="/Login" />
              <Route exact path="/Login" component={Login}/>
              <PrivateRoute path='/inicio' component={MainLayout}/>
              <Route path="*"> <NotFound/> </Route>
            </Switch>  
          </div>
        </Router>
      </AuthProvider>
      
    </ThemeProvider>
  );
}

export default App;
