import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';

const NavItem = (props) => {
  const { history, href, icon, title, ...others } = props;
  const active = href ? (history.location.pathname === href) : false;

  useEffect(()=>console.log(history.location.pathname, href), [])

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}
    >

        <Button
            onClick={()=>history.push(href)}
            component="a"
            startIcon={icon}
            disableRipple
            sx={{
                backgroundColor: active && 'rgba(255,255,255, 0.08)',
                borderRadius: 1,
                color: active ? 'secondary.main' : 'neutral.300',
                fontWeight: active && 'fontWeightBold',
                justifyContent: 'flex-start',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                '& .MuiButton-startIcon': {
                    color: active ? 'secondary.main' : 'neutral.400'
                },
                '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 0.08)'
                }
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                {title}
            </Box>
        </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string
};

export default NavItem;