import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Route, Switch } from "react-router-dom";

//Iconos
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FactCheckIcon from '@mui/icons-material/FactCheck';


import Header from '../components/Header/Header';
import NavItem from '../components/Nav/NavItem';
import LayoutUsers from './LayoutUsers';


const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));

const items = [
    {
      href: '/inicio',
      icon: (<AssignmentIndIcon fontSize="small" />),
      title: 'Usuarios'
    },
    // {
    //   href: '/inicio/cuestionario',
    //   icon: (<FactCheckIcon fontSize="small" />),
    //   title: 'Cuestionario'
    // }
];

const drawerWidth = 240;

function MainLayout(props) {
  const { history ,window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
        <div>
          <Box sx={{ p: 2 }}>
            <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/assets/images/Logotipo_Scenario_002.png`} height="50" alt=""/>
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1
              }}
            >
              <div>
                <Typography
                  color="inherit"
                  sx={{textAlign:'left'}}
                  variant="subtitle1"
                >
                  Kaleido Lab
                </Typography>
                <Typography
                  color="neutral.400"
                  variant="body2"
                >
                  VR / AR Solutions
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
      
      <List>
        {items.map((item) => (
            <NavItem
                history={history}
                key={item.title}
                icon={item.icon}
                href={item.href}
                title={item.title}
            />
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DashboardNavbarRoot
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } , color: 'neutral.900'}}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}
          <Header></Header>
        </Toolbar>
      </DashboardNavbarRoot>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'neutral.900',
              color: '#FFFFFF',
              width: 280
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'neutral.900',
              color: '#FFFFFF',
              width: 280
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, maxWidth:'100%' }}
      >

            {/* ****************************Layouts****************************/}
            <Toolbar />
            <Switch>
                <Route exact path="/inicio">
                    <LayoutUsers></LayoutUsers>
                </Route>
                <Route exact path="/inicio/customers">
                    <div style={{height:'10rem', backgroundColor:'red'}}> <h1>customers</h1> </div>
                </Route>
            </Switch>
            {/* ****************************Layouts****************************/}    

      </Box>
    </Box>
  );
}

MainLayout.propTypes = {
  window: PropTypes.func,
};

export default MainLayout;