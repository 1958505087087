import React, { useCallback, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';

import { withRouter, Redirect } from "react-router";
import { auth } from '../Firebase/FirebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { AuthContext } from '../components/Authentication/Auth';
import CopyrightLogin from '../components/Copyright/CopyrightLogin';


const Login = ({ history }) => {

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email(
          'Debe ser un correo electrónico válido')
        .max(255)
        .required(
          'Se requiere un correo electrónico válido'),
      password: Yup
        .string()
        .max(255)
        .required(
          'Se requiere la contraseña')
    }),
    onSubmit: useCallback(
      async formData => {
        if(formData.email == 'administrador@kaleidolab.co'){
          try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);
            history.push("/inicio");
          } catch (error) {
            alert("Lo sentimos, \nEl usuario y/o la contraseña no son correctos. \nInténtalo nuevamente.");
          }
        }else{
          alert("Lo sentimos, \nEl usuario y/o la contraseña no son correctos. \nInténtalo nuevamente.");
        }
        
      },
      [history]
    )
  });

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/inicio" />;
  }

  return (
    
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          // minHeight: '100%'
          height:'100vh'
        }}
      >
        <Container maxWidth="sm">
          <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/assets/images/Logotipo_Scenario.png`} height="80" alt=""/>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                INICIO DE SESIÓN
              </Typography>
            </Box>
            <Box
              sx={{
                pb: 1,
                pt: 3
              }}
            >
              <Typography
                align="center"
                color="textSecondary"
                variant="body1"
              >
                Utiliza el correo y contraseña de administrador para ingresar
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Correo electrónico"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Contraseña"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Iniciar sesión
              </Button>
            </Box>
            {/* <Typography
              color="textSecondary"
              variant="body2"
            >
              Don&apos;t have an account?
              {' '}
                <Link
                  to="/register"
                  variant="subtitle2"
                  underline="hover"
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  Sign Up
                </Link>

            </Typography> */}
          </form>
          <br></br>
        <img className="o-logo-header" src={`${process.env.PUBLIC_URL}/assets/images/LOGOS-02.png`} height="50" alt="" style={{marginTop:'1rem'}}/>
        <CopyrightLogin></CopyrightLogin>
        </Container>
      </Box>
      
  );
};

export default withRouter(Login);
