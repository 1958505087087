import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function CopyrightLogin(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        
        {new Date().getFullYear()}
        {'.'}
        {'© Kaleido Lab. Todos los derechos reservados. Registro de derecho de autor otorgado DNDA. '}
        <Link color="inherit" href="https://www.kaleidolab.scenariovr.co/politica-de-privacidad/" target="_blank">
          Política de privacidad
        </Link>{' '}
      </Typography>
    );
}