import React, { useEffect, useState } from "react";
import { app, auth } from "../../Firebase/FirebaseConfig";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import {onAuthStateChanged} from 'firebase/auth'

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    
    onAuthStateChanged(auth, (user) => {
        setCurrentUser(user)
        setPending(false)
    });
  }, []);

  if(pending){
    return( 
        <div style={{width: "100%", height:"100%", display:"flex" , justifyContent:"center", alignItems:"center", position:'absolute', }}>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};