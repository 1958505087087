import React, { useCallback, useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

// import { collection, getDocs, doc, setDoc ,query, where, ref,  set, update} from "firebase/firestore";
import { getDatabase, ref, set, update , onValue} from "firebase/database";
import { db, database } from '../../Firebase/FirebaseConfig';
import { createUserWithEmailAndPassword , deleteUser} from "firebase/auth";
import { secondaryAuth } from '../../Firebase/FirebaseConfig';

import Notification from '../Notification/Notification';

const FormUser = (props) => {
    const {set_view, onCreate} = props;
    const [loading_create, set_loading_create] = useState(false);

    const [open_notification, set_open_notification] = useState(false);
    const [message_notification, set_message_notification] = useState('');
    const [severity_notification, set_severity_notification] = useState('');

    const openNotification = (message_, severity_)=>{
        set_message_notification(message_);
        set_severity_notification(severity_);
        set_open_notification(true);
        set_open_notification(false);
    }

    const formik = useFormik({
        initialValues: {
          nombre: '',
          cedula:'',
          telefono:'',
          usuario: '',
          contrasena: '',
        },
        validationSchema: Yup.object({
            usuario: Yup
                .string()
                .email(
                'Por favor ingresa un correo electrónico válido')
                .max(255)
                .required(
                'Por favor ingresa un correo electrónico válido'),
            contrasena: Yup
                .string()
                .min(6, 'La contraseña debe tener al menos 6 caracteres')
                .max(255)
                .required(
                'Se requiere la contraseña')
        }),
        // onSubmit: (formData) => console.log(formData)
        onSubmit: async (values, { resetForm }) => {
            
            set_loading_create(true);
            createUserWithEmailAndPassword(secondaryAuth, values.usuario, values.contrasena)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log('usuario creado', user)
                console.log("User " + userCredential.user.uid + " created successfully!");
                //I don't know if the next statement is necessary 
                

                const new_user = {
                    usuario: values.usuario, 
                    contrasena: values.contrasena,
                    progreso:0,  
                }

                set(ref(database, 'respuestas/' + userCredential.user.uid ), new_user).then(()=>{
                    set_loading_create(false);
                    resetForm();
                    onCreate();
                    openNotification('Usuario creado correctamente', 'success');

                    window.location.href = `mailto:${new_user.usuario}
                              ?subject=Proceso de Selección GERENCIA SELECTA 
                              &body=¡Hola! Te invitamos a participar en el assessment de GERENCIA SELECTA enfocado en el juego de roles y toma de decisiones.
                              %0D%0AEstamos seguros que cuentas con las habilidades y competencias para llevar a cabo esta experiencia.
                              %0D%0APara participar debes acceder al siguiente link: https://scenariovr.co/ 
                              %0D%0A%0D%0ATus credenciales de acceso son: 
                              %0D%0AUSUARIO: ${new_user.usuario}
                              %0D%0ACONTRASEÑA: ${new_user.contrasena}

                              %0D%0A%0D%0A¡Te esperamos!`

                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    
                    //Eliminar el usuario de autenticación
                    deleteUser(user).then(() => {

                    }).catch((error) => {
                        
                    });

                    openNotification('Algo ha salido mal, no se pudo crear el usuario ' + errorMessage, 'error');
                    // alert('Algo ha salido mal, no se pudo crear el usuario ' + ': '+ errorMessage);
                    set_loading_create(false);
                });

                secondaryAuth.signOut();
                
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // alert('Algo ha salido mal, no se pudo crear el usuario ' + ': '+ errorMessage);
                openNotification('Algo ha salido mal, no se pudo crear el usuario ' + errorMessage, 'error');
                set_loading_create(false);
            });
        }
    });


    return (
        <>
        <form
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            // noValidate
            // {...props}
        >
        <Card>
            <CardHeader
            subheader="Completa todos los campos"
            title="Creación de usuarios"
            >
                
            </CardHeader>
            <Divider />
            <CardContent>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        error={Boolean(formik.touched.usuario && formik.errors.usuario)}
                        fullWidth
                        helperText={formik.touched.usuario && formik.errors.usuario}
                        label="Correo electrónico"
                        margin="normal"
                        name="usuario"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.usuario}
                        variant="outlined"
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        error={Boolean(formik.touched.contrasena && formik.errors.contrasena)}
                        fullWidth
                        helperText={formik.touched.contrasena && formik.errors.contrasena}
                        label="Contraseña"
                        margin="normal"
                        name="contrasena"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.contrasena}
                        variant="outlined"
                    />
                </Grid>
                
            </Grid>
            </CardContent>
            <Divider />
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2
            }}
            >
                <Button
                    disabled={loading_create}
                    onClick={()=>set_view('users')}
                    component="a"
                    startIcon={<ArrowBackIcon fontSize="small" />}
                >
                    Regresar
                </Button>

                <LoadingButton 
                    color="primary"
                    variant="contained"
                    type='submit'
                    component="span"
                    onClick={formik.handleSubmit}
                    endIcon={<AddIcon />}
                    loadingPosition="end"
                    loading={loading_create}
                >
                    {loading_create? 'creando' : 'Agregar Usuario'}
                </LoadingButton >
            </Box>
        </Card>
        </form>

        <Notification message={message_notification} severity={severity_notification} open_flag={open_notification}></Notification>
        </>
    );
};

export default FormUser;