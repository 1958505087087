import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { Button, CardHeader, Divider, CardMedia, Card, Alert, Grid, ListItemButton, ListItemIcon, FormGroup, TextField, Chip  } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import { getDatabase, ref, set, update , onValue} from "firebase/database";
import {db, database} from '../../Firebase/FirebaseConfig';

import Notification from '../Notification/Notification';

function Row(props) {
  const { row, index, userId, openNotification } = props;
  const [open, setOpen] = React.useState(false);

  const [rateVideo, setRateVideo] = useState([]);
  const [competencia, set_competencia] = useState('');
  const [calificacion, set_calificacion] = useState('');
  const [observaciones, set_observaciones] = useState('');

  const [open_select_competencia, set_open_select_competencia] = useState(false);
  const [loading_save, set_loading_save] = useState(false);


  useEffect(() => {
    // alert(row.evaluacion.IE.calificacion)
    // console.log('momento', row);
  }, []);

  useEffect(() => {
    setRateVideo(row.evaluacion? row.evaluacion : []);
    set_competencia('');
    set_calificacion('');
    set_observaciones('');
    // console.log("evaluacion de este momento", JSON.stringify(row.evaluacion));
  }, [row]);



  const handlecompetenciaChange = (event) => {
    // set_competence(event.target.value);
    const value = event.target.value;
    set_competencia(value);
    set_observaciones(rateVideo[value]?.observaciones? rateVideo[value].observaciones:'')
    set_calificacion(rateVideo[value]?.calificacion? rateVideo[value].calificacion:'')
    // console.log(rateVideo.length>0? rateVideo[value]?.calificacion:"nada aun")
    // console.log('rate video', rateVideo)
  };

  const handleClosecompetencia = () => {
    set_open_select_competencia(false);
  };

  const handleOpencompetencia = () => {
    set_open_select_competencia(true);
  };

  const handleChangeCalification = (e) => {
    
    const value = e.target.value;
    // console.log('rate cal', parseInt(value, 10));
    set_calificacion(value);
    setRateVideo({ ...rateVideo, [competencia]: {calificacion: value, observaciones} });
    
  };

  const handleObservacionesChange = (e) => {
    const value = e.target.value;
    set_observaciones(value);
    setRateVideo({ ...rateVideo, [competencia]: {calificacion, observaciones:value} });
    // console.log(rateVideo);
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    set_loading_save(true);
    setRateVideo({ ...rateVideo, [competencia]: {calificacion, observaciones} });

    // set(ref(database, 'respuestas/' + userId + '/momento' + (index+1)+ '/evaluacion'), {
    //   ...rateVideo, [competencia]: {calificacion, observaciones}
    // });
    // const starCountRef = ref(database, 'respuestas/' + userId + '/momento' + (index+1));
    update(ref(database, 'respuestas/' + userId + '/momento' + (index+1)+ '/evaluacion'), {
      ...rateVideo, [competencia]: {calificacion, observaciones}
    }).then(()=>{
      set_loading_save(false);
      openNotification('Calificación gradada correctamente', 'success');
    }).catch((error)=>{
      openNotification("Lo sentimos, los datos no se guardaron correctamente " + error, 'error');
    })

  };


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset'} }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align='left'>
          <Typography variant="button" display="block" gutterBottom>
            { 'Momento ' + (index + 1)}
          </Typography>
          
        </TableCell>

        <TableCell component="th" scope="row" align='right' >
          <Chip sx={{width:'40px', ml:1}} label="ME" size="small"
                color={ rateVideo['ME']?.calificacion? 'success' :'error'} 
                variant={competencia=='ME'? 'filled' : 'outlined'} />

          <Chip sx={{width:'40px', ml:1}} label="IE" size="small"
                color={ rateVideo['IE']?.calificacion? 'success' :'error'} 
                variant={competencia=='IE'? 'filled' : 'outlined'} />

          <Chip sx={{width:'40px', ml:1}} label="TD" size="small"
                color={ rateVideo['TD']?.calificacion? 'success' :'error'} 
                variant={competencia=='TD'? 'filled' : 'outlined'} />
          
          <Chip sx={{width:'40px', ml:1}} label="PE" size="small"
                color={ rateVideo['PE']?.calificacion? 'success' :'error'} 
                variant={competencia=='PE'? 'filled' : 'outlined'} />

          {/* <Chip sx={{width:'40px', ml:1}} label="PE" color="success" variant="outlined" size="small"/> */}
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
                {/* <Typography variant="h6" gutterBottom component="div">
                    History
                </Typography> */}
                <List dense >
                    {/* <ListItem>
                        <ListItemText  secondary={row.respuesta?.categoria} primary='Categoría'/>
                    </ListItem> */}

                    <ListItem>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          {/* <ButtonBase sx={{ width: 128, height: 128 }}>
                            <Img alt="complex" src="/static/images/grid/complex.jpg" />
                          </ButtonBase> */}
                          <Box >
                            {row.video !== ''? 
                              <Card sx={{ display: 'flex' }}>
                                  <CardMedia
                                      component='video'
                                      image={row.video}
                                      
                                      controls
                                  />
                              </Card>
                              
                              :
                              <Alert severity="info"> No hay un video disponible </Alert>
                            }

                            <>
                              <Typography gutterBottom variant="subtitle1" component="div">
                                Elementos seleccionados:
                              </Typography>
                              <nav aria-label="main mailbox folders">
                                <List>
                                  { row.elementos_seleccionados? row.elementos_seleccionados.map(
                                    (el, i) => {
                                      return (
                                        // <li key={el.tittle}></li>
                                        <ListItem dense disablePadding key={i}>
                                          <ListItemButton>
                                            <ListItemIcon>
                                              <LabelImportantIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={el.tittle} />
                                          </ListItemButton>
                                        </ListItem>
                                      );
                                    }
                                  ):row.elementos_usados?.map(
                                    (el, i) => {
                                      return (
                                        <ListItem dense disablePadding key={i}>
                                          <ListItemButton>
                                            <ListItemIcon>
                                              <LabelImportantIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={el.tittle} />
                                          </ListItemButton>
                                        </ListItem>
                                        
                                      );
                                    }
                                  )}
                                </List>
                                  
                              </nav>
                            </>
                            
                          </Box>
                        </Grid>


                        {/* AREA DEL DE EVALUACIÓN DEL VIDEO */}
                        <Grid item md={6} xs={12} container >
                          
                          <Grid item xs container direction="column" sx={{p:0}} >
                            
                            <Typography gutterBottom variant="h6"  component="div">
                              Evalúa la respuesta del { ' Momento ' + (index + 1)}
                            </Typography>

                            {/* <form
                              style={{width:'100%'}}
                              autoComplete="off"
                              onSubmit={handleSubmit}
                            >      */}
                              <Button sx={{ display: 'block', mt: 2, textAlign:'left' }} onClick={handleOpencompetencia}>
                                Selecciona una competencia y evalúa cada una de ellas:
                              </Button>
                              <FormControl sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="competence-open-select-label">Competencia</InputLabel>
                                <Select
                                  fullWidth
                                  variant="outlined"
                                  // isSearchable={true}
                                  labelId="competence-open-select-label"
                                  id="competence-open-select"
                                  open={open_select_competencia}
                                  onClose={handleClosecompetencia}
                                  onOpen={handleOpencompetencia}
                                  value={competencia}
                                  label="Competencia"
                                  onChange={handlecompetenciaChange}
                                >
                                  <MenuItem value="">
                                    <em>Ninguna</em>
                                  </MenuItem>
                                  <MenuItem value={'ME'}>Mentalidad experimental (ME)</MenuItem>
                                  <MenuItem value={'IE'}>Inteligencia emocional (IE)</MenuItem>
                                  <MenuItem value={'TD'}>Toma de decisiones (TD)</MenuItem>
                                  <MenuItem value={'PE'}>Pensamiento estratégico (PE)</MenuItem>
                                </Select>

                                
                                
                                {competencia!=='' &&
                                  // <Paper sx={{ p: 0, margin: 'auto', flexGrow: 1, width:'100%'}}>
                                <FormControl sx={{my:2}} spacing={2}>
                                    <Divider sx={{mb:2}} variant="middle" />
                                    {/* <InputLabel id="calification-open-select-label">Calificación</InputLabel> */}
                                    <TextField
                                      // fullWidth
                                      // sx={{width:'100%'}}
                                      select
                                      required
                                      variant='outlined'
                                      // labelId="calification-open-select-label"
                                      id="calification-open-select"
                                      value={calificacion}
                                      label="Calificación"
                                      onChange={handleChangeCalification}
                                    >
                                      {/* <MenuItem value="">
                                        <em>Ninguna</em>
                                      </MenuItem> */}
                                      <MenuItem value={1}>1. No se evidencia el comportamiento</MenuItem>
                                      <MenuItem value={2}>2. Se evidencia el comportamiento de forma escasa</MenuItem>
                                      <MenuItem value={3}>3. Se evidencia el comportamiento</MenuItem>
                                      <MenuItem value={4}>4. Se evidencia el comportamiento en la mayoría de las ocasiones</MenuItem>
                                      <MenuItem value={5}>5. Se evidencia el comportamiento constantemente</MenuItem>
                                    </TextField>

                                    <TextField
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        label="Observaciones"
                                        margin="normal"
                                        name="observaciones"
                                        onChange={(e) => handleObservacionesChange(e)}
                                        type="number"
                                        value={observaciones}
                                        variant="outlined"
                                    />
                                  

                                    <LoadingButton 
                                        sx={{mt:1}}
                                        disabled={calificacion==''}
                                        color="primary"
                                        variant="contained"
                                        type='submit'
                                        component="span"
                                        onClick={handleSubmit}
                                        endIcon={<SaveIcon />}
                                        loadingPosition="end"
                                        loading={loading_save}>
                                      {loading_save? 'Guradando' : 'Guardar calificación'}
                                    </LoadingButton >

                                </FormControl>
                                }
                                
                              </FormControl>
                            
                            {/* </form>   */}
                          </Grid>
                          
                        </Grid>
                      </Grid>
                    </ListItem>


                    
                    
                    

                    
                </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    video: PropTypes.string.isRequired,
    // respuesta: 
    //   PropTypes.shape({
    //     categoria: PropTypes.string.isRequired,
    //     opcion: PropTypes.string,
    //     puntaje: PropTypes.number,
        
    //   }),
    
  }).isRequired,
};

export default function DataUser({data, set_view}) {

    const [name, set_name] = useState('');
    const [moments, setMoments] = useState([]);

    const [open_notification, set_open_notification] = useState(false);
    const [message_notification, set_message_notification] = useState('');
    const [severity_notification, set_severity_notification] = useState('');

    const openNotification = (message_, severity_)=>{
        set_message_notification(message_);
        set_severity_notification(severity_);
        set_open_notification(true);
        set_open_notification(false);
    }

    useEffect(()=>{
      // console.log('datos', data)
      set_name(data.usuario);

      const momentList = [];
      for (let mom in data) {

        if(mom.includes("momento")){
          momentList.push({ ...data[mom] });
        }

      }
      // console.log('user data moments',momentList)
      setMoments(momentList);
    },[data]);

    return (
      <>
        <TableContainer component={Paper}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems:'center',
                    p: 0
                }}
            >

                <CardHeader sx={{textAlign:'left'}} 
                  subheader={
                    <Box sx={{ width: '100%'}}>
                      <Box sx={{ fontWeight: 'bold', display: 'flex'}}>Usuario:<Box sx={{ fontWeight: 'light', ml:1}}>{name}</Box></Box>

                      <Box sx={{ fontWeight: 'bold', display: 'flex'}}>Contraseña:<Box sx={{ fontWeight: 'light', ml:1}}>{data.contrasena}</Box></Box>
                      
                    </Box>
                  } 
                  title={<Button
                          // disabled={loading_create}
                          sx={{ml:0, mb:1}}
                          onClick={()=>set_view('users')}
                          component="a"
                          startIcon={<ArrowBackIcon fontSize="small" />}
                      >
                          Regresar
                      </Button>
                  }
                />
            </Box>
        
            <Divider />
            
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell />
                  <TableCell align='left'>
                    <Typography variant="h6" gutterBottom component="div">
                      Etapas
                    </Typography>
                  </TableCell>
                <TableCell />
                
            </TableRow>
            </TableHead>
            <TableBody>
              {moments.map((row, key) => (
                <Row key={key} row={row} index={key} userId={data.id} openNotification={openNotification}/>
              ))}
              {/* <RowVideo videoUrl={video_url}></RowVideo> */}
            </TableBody>
        </Table>
        </TableContainer>

        <Notification message={message_notification} severity={severity_notification} open_flag={open_notification}></Notification>
      </>
    );
}